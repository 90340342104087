<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
            <template v-slot:body>
                <b-overlay :show="loading">
                    <b-row>
                    <b-col lg="12" sm="12">
                        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                        <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                            <ValidationProvider name="Course Document Name (En)" vid="document_name" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="5"
                                label-for="document_name"
                                slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{ $t('elearning_config.course_document_name_en') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                id="document_name"
                                v-model="trainingType.document_name"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Course Document Name (Bn)" vid="document_name_bn" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="5"
                                label-for="document_name_bn"
                                slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{$t('elearning_config.course_document_name_bn')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                id="document_name_bn"
                                v-model="trainingType.document_name_bn"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Remkars (En)" vid="remarks">
                            <b-form-group
                                class="row"
                                label-cols-sm="5"
                                label-for="remarks"
                                slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{ $t('elearning_config.remarks_en') }}
                                </template>
                                <b-form-input
                                id="remarks"
                                v-model="trainingType.remarks"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Remkars (Bn)" vid="remarks_bn">
                            <b-form-group
                                class="row"
                                label-cols-sm="5"
                                label-for="remarks_bn"
                                slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{$t('elearning_config.remarks_bn')}}
                                </template>
                                <b-form-input
                                id="remarks_bn"
                                v-model="trainingType.remarks_bn"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <div class="row">
                            <div class="col-sm-3"></div>
                            <div class="col text-right">
                                <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                &nbsp;
                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                            </div>
                            </div>
                        </b-form>
                        </ValidationObserver>
                    </b-col>
                    </b-row>
                </b-overlay>
            </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { courseDocumentSetupStore, courseDocumentSetupUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getEditingData()
      this.trainingType = tmp
    }
    this.getOrganizationlist()
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      loading: false,
      totalPost: 0,
      saveBtnName: this.id ? this.$t('elearning_config.update') : this.$t('globalTrans.save'),
      trainingType: {
        document_name: '',
        document_name_bn: '',
        remarks: '',
        remarks_bn: ''
      }
    }
  },
  computed: {
  },
  methods: {
    getEditingData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

      if (this.trainingType.id) {
        result = await RestApi.putData(trainingElearningServiceBaseUrl, `${courseDocumentSetupUpdate}/${this.id}`, this.trainingType)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, courseDocumentSetupStore, this.trainingType)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.commit('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('elearning_config.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
      this.loading = false
    },
    getOrganizationlist () {
      const orgList = this.$store.state.commonObj.organizationProfileList
      this.organizationList = orgList.map(obj => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text }
        }
      })
    }
  }
}
</script>
